import mkFormData from '@helper/formData.js'
import apiClient from '@/services/ApiClient'

export default {
  namespaced: true,
  state: {
    step: 1,
    sendState: '',
    formData: {
      offer_id: '',
      car_id: '',
      address_title: 'Herr',
      address_surname: '',
      address_name: '',
      address_firm: '',
      address_street: '',
      address_city: '',
      address_postal: '',
      address_tel1: '',
      address_email: '',
      kaufplanung: '',
      barkauf: false,
      finanzierung: true,
      laufzeit: '',
      laufleistung: '',
      anzahlung: '',
      order_msg_text: '',
      einwilligung: false,
      captcha: '',
      wagennummer: '',
      addBoxes: {}, // { 1: { label: 'grün', value: false } },
    },
  },
  getters: {
    getFormData: (state) => state.formData,
  },
  mutations: {
    initFormData(state) {
      const init = {
        // offer_id: '123',
        car_id: '456',
        address_surname: 'Karl',
        address_name: 'Valentin',
        address_firm: 'Spass AG',
        address_street: 'Wiener Str. 1',
        address_city: 'München',
        address_postal: '80885',
        address_tel1: '089 123456',
        address_email: 'info@its-gering.de',
        kaufplanung: '24 Monate',
        wagennummer: 'Wagen 1',
      }

      for (const key in init) {
        state.formData[key] = init[key]
      }
    },
    setFormData(state, formdata) {
      state.formData = formdata
    },
    setStep(state, step) {
      state.step = step
    },
    initKaufplanung(state, kaufplanung) {
      state.formData.kaufplanung = kaufplanung
    },
    initLaufzeit(state, laufzeit) {
      state.formData.laufzeit = laufzeit
    },
    initLaufleistung(state, laufleistung) {
      state.formData.laufleistung = laufleistung
    },
    initAnzahlung(state, anzahlung) {
      state.formData.anzahlung = anzahlung
    },
    setSendState(state, sendState) {
      state.sendState = sendState
    },
    setAddBoxes(state, addBoxes) {
      state.formData.addBoxes = addBoxes
    },
    setOfferId(state, offer_id) {
      state.formData.offer_id = offer_id
    },
    setNumber(state, so_number) {
      state.formData.so_number = so_number
    },
  },
  actions: {
    initAddBoxes(context) {
      const addBoxes =
        context.rootState.filter.detail.so_request_options3.split('\n')
      const oAddBoxes = {}
      addBoxes.forEach(
        (box, ix) => (oAddBoxes[ix] = { label: box, value: false })
      )
      context.commit('setAddBoxes', oAddBoxes)
      context.commit('setOfferId', context.rootState.filter.detail.offer_id)
      context.commit('setNumber', context.rootState.filter.detail.so_number)
    },
    async send(context) {
      // const id = context.state.currentModel
      const formData = context.state.formData

      let requestData = {
        mode: 'sendRequest',
        reCaptchaResponse: formData.reCaptchaResponse,
        order_agent_token: context.rootState.env.orderAgentToken,
        market: context.rootState.env.market,
        offer_id: context.rootState.filter.detail.offer_id,
        address_title: formData.address_title,
        address_surname: formData.address_surname,
        address_name: formData.address_name,
        address_firm: formData.address_firm,
        address_street: formData.address_street,
        address_city: formData.address_city,
        address_postal: formData.address_postal,
        address_tel1: formData.address_tel1,
        address_email: formData.address_email,
        order_msg_text: formData.order_msg_text,
        so_vehicle: formData.wagennummer,
        so_buyplan: formData.kaufplanung,
        offer_number: context.rootState.filter.detail.so_number,
      }

      if (
        context.rootState.filter.detail.so_category == 'Leasing' ||
        context.rootState.filter.detail.so_category == 'Finanzierung'
      ) {
        requestData.so_request_options1 = formData.laufzeit
        requestData.so_request_options2 = formData.laufleistung
        requestData.so_request_initial = formData.anzahlung
      }

      if (context.rootState.filter.detail.so_file_pdf2) {
        requestData.wagennummer = formData.wagennummer
      }

      const aAddBoxes = []
      for (const key in formData.addBoxes) {
        if (formData.addBoxes[key].value) {
          aAddBoxes.push(formData.addBoxes[key].label)
        }
      }

      if (aAddBoxes) {
        requestData.so_request_options3 = aAddBoxes.join('|')
      }

      // console.log('requestData', requestData)

      try {
        const response = await apiClient.post('/', mkFormData(requestData))
        context.commit('setSendState', response.data)
        context.commit('setStep', 6)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
