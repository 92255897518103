<template>
  <div>
    <div class="its-ak-mt-6">
      <section
        v-if="
          getDetail.so_category == 'Leasing' ||
          getDetail.so_category == 'Finanzierung'
        "
      >
        <VSelect
          :vref="$v.formData.laufleistung"
          :label="$t('laufleistung')"
          :options="laufleistungOptions"
        />

        <VSelect
          :vref="$v.formData.laufzeit"
          :label="$t('laufzeit')"
          :options="laufzeitOptions"
        />

        <VText :vref="$v.formData.anzahlung" :label="$t('anzahlung')" />
      </section>

      <AddBoxes />
    </div>

    <FormButtons
      @next="submit()"
      @back="back()"
      :step="3"
      :submitDisabled="submitDisabled"
    />
  </div>
</template>

<script>
// import VText from '@vuelidate/VText.vue'
import AddBoxes from '@components/AddBoxes.vue'
import VSelect from '@vuelidate/VSelect.vue'
import VText from '@vuelidate/VText.vue'
import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'
import FormButtons from '@components/FormButtons.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'DetailForm3',
  mixins: [vuelidatePublicMixin],
  components: {
    AddBoxes,
    VSelect,
    VText,
    FormButtons,
  },
  computed: {
    ...mapGetters('filter', ['getDetail']),
    laufzeitOptions() {
      const options = this.$store.state.filter.detail.so_request_options1
        .trim()
        .split('\n')
        .map((el) => ({ label: el, value: el }))

      if (!this.$store.state.formData.formData.laufzeit) {
        this.$store.commit('formData/initLaufzeit', options[0].value)
      }

      return options
    },

    laufleistungOptions() {
      const options = this.$store.state.filter.detail.so_request_options2
        .trim()
        .split('\n')
        .map((el) => ({ label: el, value: el }))

      if (!this.$store.state.formData.formData.laufleistung) {
        this.$store.commit('formData/initLaufleistung', options[0].value)
      }

      return options
    },

    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  methods: {
    submit() {
      if (!this.validateAll()) {
        return
      }
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 4)
    },
    back() {
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 2)
    },
  },
  validations() {
    let validations = {
      formData: {
        finanzierung: {},
        barkauf: {},
        laufzeit: {},
        laufleistung: {},
        anzahlung: { required },
      },
    }

    return validations
  },
  created() {
    if (this.$store.state.filter.detail.so_request_initial) {
      this.$store.commit(
        'formData/initAnzahlung',
        this.$store.state.filter.detail.so_request_initial.trim()
      )
    }
  },
}
</script>
