<template>
  <div v-if="getDetail && $store.state.env.langLoaded" class="detail">
    <div class="wrapper_back_button">
      <button class="button_ak button_ak_light" @click="routeModelle()">
        {{ $t('zurueck-zur-modelluebersicht') }}
      </button>
    </div>
    <div class="detail_inner">
      <div
        class="detail_headline txtclr_prim txtsize6"
        v-html="getDetail.so_make_model1"
      />
      <div class="category txtclr_sec txtsize4">
        <span v-if="getDetail.so_category == 'Finanzierung'">{{
          $t('angebot-finanzierung')
        }}</span>
        <span v-if="getDetail.so_category == 'Leasing'">{{
          $t('angebot-leasing')
        }}</span>
        <span v-if="getDetail.so_category == 'Bar'">{{
          $t('angebot-bar')
        }}</span>

        - {{ $t('angebot') }}
      </div>

      <div class="number"># {{ getDetail.so_number }}</div>

      <div
        class="
          offer_top
          its-ak-grid its-ak-grid-cols-1
          sm:its-ak-grid-cols-2
          its-ak-gap-3
        "
      >
        <div class="wrapper_model_image">
          <img class="model_image" :src="getDetail.so_image" />
          <div
            class="wrapper_amount its-ak-text-center its-ak-my-6"
            v-if="getDetail.so_amount != ''"
          >
            {{ $t('nur-noch') }}
            <span
              class="its-ak-text-center txtclr_sec txtsize7"
              v-html="getDetail.so_amount"
            />
            {{ $t('fahrzeuge-vorhanden') }}
          </div>
          <div
            class="wrapper_ad_image wrapper_ad_image1"
            v-if="getDetail.so_ad_image1 != ''"
          >
            <a
              v-if="getDetail.so_ad_url1 != ''"
              :href="getDetail.so_ad_url1"
              target="_blank"
            >
              <img class="ad_image1" :src="getDetail.so_ad_image1" />
            </a>
            <img v-else class="ad_image1" :src="getDetail.so_ad_image1" />
          </div>
          <div
            class="wrapper_ad_desc"
            v-if="getDetail.so_ad_text1 != ''"
            v-html="getDetail.so_ad_text1"
          />
        </div>

        <div class="wrapper_offer_specs its-ak-text-center">
          <div v-if="getDetail.so_category == 'Leasing'">
            {{ $t('leasingrate-ab') }}
          </div>
          <div v-else-if="getDetail.so_category == 'Finanzierung'">
            {{ $t('finanzierungsrate-ab') }}
          </div>
          <div v-else-if="getDetail.so_category == 'Bar'">
            {{ $t('barzahlungspreis-ab') }}
          </div>
          <div class="price txtclr_sec txtsize7" v-html="getDetail.so_price" />
          <div v-if="getDetail.so_discount != ''">
            <div>{{ $t('nachlass-auf-uvp') }}</div>
            <div
              class="discount txtclr_prim txtsize6"
              v-html="getDetail.so_discount"
            />
          </div>
          <div
            class="note1 txtsize4 txtclr_prim"
            v-if="getDetail.so_note1 != ''"
            v-html="getDetail.so_note1"
          />
          <div class="wrapper_button">
            <div>{{ $t('unverbindliche-anfrage-stellen') }}</div>
            <a class="button_ak button_ak_small" href="#form_anfrage">
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    fill="none"
                    stroke="#000"
                    points="1.4,6.5 10,11 18.6,6.5"
                  ></polyline>
                  <path
                    d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"
                  ></path>
                </svg>
              </span>
              {{ $t('angebot-anfordern') }}
            </a>
          </div>
        </div>
      </div>

      <div class="model_headline txtclr_prim txtsize5">
        <span>{{ $t('angebot-im-detail') }}: </span>
        <span class="bolder_ak" v-html="getDetail.so_make_model2" />
      </div>

      <div
        class="
          offer_main
          its-ak-grid its-ak-grid-cols-1
          sm:its-ak-grid-cols-2
          its-ak-gap-3
        "
      >
        <div class="offer_main1 bg1">
          <div class="wrapper_thumbs its-ak-flex its-ak-flex-wrap its-ak-gap-5">
            <div
              v-for="(img, mIx) in getDetail.so_images_gal"
              :key="mIx"
              class="wrapper_detail_thumb"
              @click="showLightbox(mIx)"
            >
              <img class="detail_thumb" :src="img" />
            </div>
          </div>
          <div class="its-ak-text-right">{{ $t('bsp-abbildungen') }}</div>
          <div class="wrapper_model_desc">
            <div class="txtclr_prim txtsize3">
              {{ $t('modellbeschreibung') }}
            </div>
            <div class="desc_text1" v-html="getDetail.so_model_text1" />
            <div
              class="button_ak button_ak_more fit"
              v-if="getDetail.so_model_text2 != '' && !more.modelText"
              @click="more.modelText = true"
            >
              Mehr anzeigen &gt;&gt;
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="9" y="1" width="1" height="17"></rect>
                  <rect x="1" y="9" width="17" height="1"></rect>
                </svg>
              </span>
            </div>
            <div
              v-if="getDetail.so_model_text2 != '' && more.modelText"
              class="desc_text2"
              v-html="getDetail.so_model_text2"
            />
            <div
              class="button_ak button_ak_more fit"
              v-if="getDetail.so_model_text2 != '' && more.modelText"
              @click="more.modelText = false"
            >
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect height="1" width="18" y="9" x="1"></rect>
                </svg>
              </span>
              {{ $t('weniger-anzeigen') }} &lt;&lt;
            </div>
          </div>
          <div
            class="
              model_note
              txtsize4
              txtclr_prim
              its-ak-text-center its-ak-mt-4
            "
            v-if="getDetail.so_model_note != ''"
            v-html="getDetail.so_model_note"
          />
          <hr />
          <div class="wrapper_button its-ak-text-center its-ak-mt-4">
            <div>{{ $t('unverbindliche-anfrage-stellen') }}</div>
            <a class="button_ak button_ak_small" href="#form_anfrage">
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    fill="none"
                    stroke="#000"
                    points="1.4,6.5 10,11 18.6,6.5"
                  ></polyline>
                  <path
                    d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"
                  ></path>
                </svg>
              </span>
              {{ $t('angebot-anfordern') }}
            </a>
          </div>

          <div
            class="wrapper_button_configuration its-ak-text-center its-ak-mt-8"
            v-if="getDetail.so_configuration_url != ''"
          >
            <div class="txtsize4 txtclr5">
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    stroke-width="1.03"
                    d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"
                  ></path>
                </svg>
              </span>
              {{ $t('wunschausst-moeglich') }}
            </div>
            <div class="its-ak-text-center its-ak-mt-2">
              <div
                class="button_ak button_ak_more fit"
                v-if="!more.configurationText"
                @click="more.configurationText = true"
              >
                {{ $t('mehr-anzeigen') }} &gt;&gt;
                <span class="icon_ak">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="9" y="1" width="1" height="17"></rect>
                    <rect x="1" y="9" width="17" height="1"></rect>
                  </svg>
                </span>
              </div>
              <div v-if="more.configurationText" class="desc_text2">
                {{ $t('text') }}
              </div>
              <div
                class="button_ak button_ak_more fit"
                v-if="more.configurationText"
                @click="more.configurationText = false"
              >
                <span class="icon_ak">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect height="1" width="18" y="9" x="1"></rect>
                  </svg>
                </span>
                {{ $t('weniger-anzeigen') }} &lt;&lt;
              </div>
            </div>
            <a
              class="button_ak button_ak_light its-ak-mt-4"
              :href="getDetail.so_configuration_url"
              target="_blank"
            >
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    fill="none"
                    stroke="#000"
                    cx="9.997"
                    cy="10"
                    r="3.31"
                  ></circle>
                  <path
                    fill="none"
                    stroke="#000"
                    d="M18.488,12.285 L16.205,16.237 C15.322,15.496 14.185,15.281 13.303,15.791 C12.428,16.289 12.047,17.373 12.246,18.5 L7.735,18.5 C7.938,17.374 7.553,16.299 6.684,15.791 C5.801,15.27 4.655,15.492 3.773,16.237 L1.5,12.285 C2.573,11.871 3.317,10.999 3.317,9.991 C3.305,8.98 2.573,8.121 1.5,7.716 L3.765,3.784 C4.645,4.516 5.794,4.738 6.687,4.232 C7.555,3.722 7.939,2.637 7.735,1.5 L12.263,1.5 C12.072,2.637 12.441,3.71 13.314,4.22 C14.206,4.73 15.343,4.516 16.225,3.794 L18.487,7.714 C17.404,8.117 16.661,8.988 16.67,10.009 C16.672,11.018 17.415,11.88 18.488,12.285 L18.488,12.285 Z"
                  ></path>
                </svg>
              </span>
              &nbsp;
              <span v-html="getCurrentMarkeName" />: {{ $t('konfigurieren') }}
            </a>
          </div>
        </div>
        <div class="offer_main2">
          <div v-if="getDetail.so_file_pdf2" class="pdf_box bg3">
            <div @click="openWindow(getDetail.so_file_pdf2[0])">
              <div class="pdf_box_icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    fill="none"
                    stroke="#FFF"
                    width="13"
                    height="17"
                    x="3.5"
                    y="1.5"
                  ></rect>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="12"
                    y1="12.5"
                    y2="12.5"
                  ></line>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="14"
                    y1="8.5"
                    y2="8.5"
                  ></line>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="14"
                    y1="6.5"
                    y2="6.5"
                  ></line>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="14"
                    y1="10.5"
                    y2="10.5"
                  ></line>
                </svg>
              </div>
              <div class="pdf_box_title">{{ $t('wagenliste-angebot') }}</div>
              <span>
                {{ $t('wagennummer-merken-text') }}
                <strong>{{ $t('hier-klicken') }}</strong>
              </span>
            </div>
          </div>
          <div v-if="getDetail.so_file_pdf" class="pdf_box bg3">
            <div @click="openWindow(getDetail.so_file_pdf[0])">
              <div class="pdf_box_icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    fill="none"
                    stroke="#FFF"
                    width="13"
                    height="17"
                    x="3.5"
                    y="1.5"
                  ></rect>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="12"
                    y1="12.5"
                    y2="12.5"
                  ></line>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="14"
                    y1="8.5"
                    y2="8.5"
                  ></line>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="14"
                    y1="6.5"
                    y2="6.5"
                  ></line>
                  <line
                    fill="none"
                    stroke="#FFF"
                    x1="6"
                    x2="14"
                    y1="10.5"
                    y2="10.5"
                  ></line>
                </svg>
              </div>
              <div class="pdf_box_title">
                {{ $t('hier-alle-daten-dokument') }}
              </div>
              <span>
                {{ $t('alle-daten-herunterladen') }}
                <strong>{{ $t('hier-klicken') }}</strong>
              </span>
            </div>
          </div>
          <div
            v-if="getDetail.so_file_pdf"
            class="button_ak button_ak_light fit its-ak-mb-3"
            @click="openWindow(getDetail.so_file_pdf[0])"
          >
            <span class="icon_ak">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  fill="none"
                  stroke="#000"
                  width="13"
                  height="17"
                  x="3.5"
                  y="1.5"
                ></rect>
                <path
                  d="M14.65 11.67c-.48.3-1.37-.19-1.79-.37a4.65 4.65 0 0 1 1.49.06c.35.1.36.28.3.31zm-6.3.06l.43-.79a14.7 14.7 0 0 0 .75-1.64 5.48 5.48 0 0 0 1.25 1.55l.2.15a16.36 16.36 0 0 0-2.63.73zM9.5 5.32c.2 0 .32.5.32.97a1.99 1.99 0 0 1-.23 1.04 5.05 5.05 0 0 1-.17-1.3s0-.71.08-.71zm-3.9 9a4.35 4.35 0 0 1 1.21-1.46l.24-.22a4.35 4.35 0 0 1-1.46 1.68zm9.23-3.3a2.05 2.05 0 0 0-1.32-.3 11.07 11.07 0 0 0-1.58.11 4.09 4.09 0 0 1-.74-.5 5.39 5.39 0 0 1-1.32-2.06 10.37 10.37 0 0 0 .28-2.62 1.83 1.83 0 0 0-.07-.25.57.57 0 0 0-.52-.4H9.4a.59.59 0 0 0-.6.38 6.95 6.95 0 0 0 .37 3.14c-.26.63-1 2.12-1 2.12-.3.58-.57 1.08-.82 1.5l-.8.44A3.11 3.11 0 0 0 5 14.16a.39.39 0 0 0 .15.42l.24.13c1.15.56 2.28-1.74 2.66-2.42a23.1 23.1 0 0 1 3.59-.85 4.56 4.56 0 0 0 2.91.8.5.5 0 0 0 .3-.21 1.1 1.1 0 0 0 .12-.75.84.84 0 0 0-.14-.25z"
                ></path>
              </svg>
            </span>
            {{ $t('download-pdf-datei') }}
          </div>
          <div class="wrapper_model_desc">
            <div
              class="note2 its-ak-my-4 txtsize4 txtclr_prim"
              v-if="getDetail.so_note2 != ''"
              v-html="getDetail.so_note2"
            />
            <div class="txtclr_prim txtsize3">{{ $t('ang-highlights') }}</div>
            <div class="text1" v-html="getDetail.so_text1" />
            <div
              class="button_ak button_ak_more fit"
              v-if="getDetail.so_text2 != '' && !more.highlights"
              @click="more.highlights = true"
            >
              {{ $t('mehr-anzeigen') }} &gt;&gt;
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="9" y="1" width="1" height="17"></rect>
                  <rect x="1" y="9" width="17" height="1"></rect>
                </svg>
              </span>
            </div>
            <div
              v-if="getDetail.so_text2 != '' && more.highlights"
              class="text2"
              v-html="getDetail.so_text2"
            />
            <div
              class="button_ak button_ak_more fit"
              v-if="getDetail.so_text2 != '' && more.highlights"
              @click="more.highlights = false"
            >
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect height="1" width="18" y="9" x="1"></rect>
                </svg>
              </span>
              {{ $t('weniger-anzeigen') }} &lt;&lt;
            </div>
          </div>
          <hr />
          <div class="wrapper_button its-ak-text-center">
            <div>{{ $t('unverbindliche-anfrage-stellen') }}</div>
            <a class="button_ak button_ak_small" href="#form_anfrage">
              <span class="icon_ak">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    fill="none"
                    stroke="#000"
                    points="1.4,6.5 10,11 18.6,6.5"
                  ></polyline>
                  <path
                    d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"
                  ></path>
                </svg>
              </span>
              {{ $t('angebot-anfordern') }}
            </a>
          </div>
          <hr />
          <div
            class="wrapper_ad_image wrapper_ad_image2"
            v-if="getDetail.so_ad_image2 != ''"
          >
            <a
              v-if="getDetail.so_ad_url2 != ''"
              :href="getDetail.so_ad_url2"
              target="_blank"
            >
              <img class="ad_image2" :src="getDetail.so_ad_image2" />
            </a>
            <img v-else class="ad_image2" :src="getDetail.so_ad_image2" />
          </div>
          <div
            class="wrapper_ad_desc"
            v-if="getDetail.so_ad_text2 != ''"
            v-html="getDetail.so_ad_text2"
          />
        </div>
      </div>

      <div class="model_specs_headline txtclr_prim txtsize5">
        <span>{{ $t('verbrauchswerte') }}<sup>*</sup>: </span>
        <span class="bolder_ak">{{ getDetail.so_make_model1 }}</span>
      </div>

      <div class="wrapper_model_specs">
        <div
          class="
            its-ak-grid its-ak-grid-cols-1
            sm:its-ak-grid-cols-3
            its-ak-gap-3
          "
        >
          <div>
            <div class="specs_head">{{ $t('motoriesierung') }}</div>
            <div class="model_data" v-html="getDetail.so_model_data" />
          </div>
          <div>
            <div class="specs_head">{{ $t('verbrauch') }}</div>
            <div class="model_cons" v-html="getDetail.so_model_cons" />
          </div>
          <div v-if="getDetail.so_eff_class">
            <img
              :src="getImageUrl + getDetail.so_eff_class.toLowerCase() + '.png'"
            />
          </div>
        </div>
        <div class="wrapper_cons_text">
          <p v-html="$t('verbrauch-text')" />
        </div>
      </div>

      <hr />

      <div class="wrapper_anfrage" id="form_anfrage">
        <div
          class="
            anfrage_head
            its-ak-grid its-ak-grid-cols-1
            sm:its-ak-grid-cols-5
          "
        >
          <div class="bg4 head1 txtclr4 its-ak-grid">
            <div class="its-ak-self-center its-ak-justify-self-center">
              <span class="icon_ak">
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    fill="none"
                    stroke="#000"
                    points="1.4,6.5 10,11 18.6,6.5"
                  ></polyline>
                  <path
                    d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          <div class="bg3 txtclr4 txtsize4 head2 its-ak-col-span-4">
            {{ $t('anfrage-fuer') }}:
            <span class="bolder" v-html="getDetail.so_make_model2" />
          </div>
        </div>

        <div
          class="
            its-ak-grid its-ak-grid-cols-1
            sm:its-ak-grid-cols-2
            its-ak-gap-3
          "
        >
          <div class="wrapper_form">
            <DetailForm1 v-if="$store.state.formData.step === 1" />
            <DetailForm2 v-if="$store.state.formData.step === 2" />
            <DetailForm3 v-if="$store.state.formData.step === 3" />
            <DetailForm4 v-if="$store.state.formData.step === 4" />
            <DetailForm5 v-if="$store.state.formData.step === 5" />
            <DetailForm6 v-if="$store.state.formData.step === 6" />
          </div>
          <div class="anfrage_desc bg1">
            <div class="anfrage_desc_head">
              "{{ $t('anfragestellung-titel') }}"
            </div>
            <div class="uk-panel uk-margin">
              <p>
                {{ $t('anfragestellung-text1') }}
              </p>
              <p v-html="$t('anfragestellung-text2')" />
              <p v-html="$t('anfragestellung-text3')" />
              <p v-html="$t('anfragestellung-text4')" />
              <p>
                <a target="_blank" :href="getAgentData.commissionPrivacyLink">
                  {{ $t('anfragestellung-datenschutz') }} &gt;&gt;
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div v-html="getModelle.headline" />
      <div
        v-for="(model, mIx) in getModelle.list_items"
        :key="mIx"
        @click="offers(model.id)"
      >
        <img class="main-image" :src="model.so_image" />
        <div v-html="model.so_make_model" />
        <div v-html="model.so_price" />
        <div v-html="model.so_text_panel" />
        <button>Alle Angebote</button>
      </div> -->
    </div>

    <div class="wrapper_back_button">
      <button class="button_ak button_ak_light" @click="routeModelle()">
        {{ $t('zurueck-zur-modelluebersicht') }}
      </button>
    </div>

    <CoolLightBox
      :items="lightbox.items"
      :index="lightbox.index"
      @close="lightbox.index = null"
      :gallery="false"
      :slideshow="false"
    >
    </CoolLightBox>
  </div>

  <div v-else class="loading">...loading</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import DetailForm1 from '@components/DetailForm1.vue'
import DetailForm2 from '@components/DetailForm2.vue'
import DetailForm3 from '@components/DetailForm3.vue'
import DetailForm4 from '@components/DetailForm4.vue'
import DetailForm5 from '@components/DetailForm5.vue'
import DetailForm6 from '@components/DetailForm6.vue'
import { mapActions, mapGetters } from 'vuex'
import aplus from '@images/aplus.png'
import a from '@images/a.png'
import b from '@images/b.png'
import c from '@images/c.png'
import d from '@images/d.png'
import e from '@images/e.png'
import f from '@images/f.png'
import g from '@images/g.png'

export default {
  components: {
    DetailForm1,
    DetailForm2,
    DetailForm3,
    DetailForm4,
    DetailForm5,
    DetailForm6,
    CoolLightBox,
  },
  name: 'Detail',
  computed: {
    ...mapGetters('filter', ['getDetail', 'getCurrentMarkeName']),
    ...mapGetters('env', ['getAgentData', 'getImageUrl']),
  },
  data() {
    return {
      lightbox: {
        index: null,
        items: [],
      },
      more: {
        modelText: false,
        highlights: false,
        configurationText: false,
      },
    }
  },
  methods: {
    ...mapActions('env', ['routeModelle']),
    showLightbox(index) {
      if (
        !this.lightbox.items.length &&
        this.getDetail &&
        this.getDetail.so_images_gal
      ) {
        this.lightbox.items = this.getDetail.so_images_gal
      }
      if (this.lightbox.items) {
        this.lightbox.index = index
      }
    },
    openWindow: function (link) {
      window.open(link, '_blank')
    },
    getImage(name) {
      const images = {
        aplus,
        a,
        b,
        c,
        d,
        e,
        f,
        g,
      }

      return images[name]
    },
  },
}
</script>

<style lang="scss"></style>
