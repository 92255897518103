<template>
  <div class="wrapper_ak">
    <div class="aktionsangebote">
      <component :is="getCurrentComp" />
    </div>
  </div>
</template>

<style lang="scss">
$primary: #00698c;
$secondary: #c1c100;
$txt-clr1: #696969;
$txt-clr2: #539ab1;
$txt-clr3: #e44c4c;
$txt-clr4: #fff;
$txt-clr5: rgba(0, 0, 0, 0.87);
$txt-clr6: #a6ff4d;
$txt-clr7: rgba(255, 255, 255, 0.7);
$txt1: 15px;
$txt2: 17px;
$txt3: 20px;
$txt4: 22px;
$txt5: 34px;
$txt6: 40px;
$txt7: 60px;
$bg1: #f4f5f7;
$bg2: #1a7999;
$bg3: #01698c;
$bg4: #fff;
$bg5: #147595;

.tooltip-inner {
  font-size: 14px !important;
  max-width: 300px;
}

.wrapper_ak {
  margin: 0 auto;
  max-width: 1200px;
  padding: 15px;
  font-family: Roboto;
  font-size: $txt1;
  line-height: 1.4;

  & select,
  select option {
    font-family: Roboto;
    font-size: $txt1;
  }

  & input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  select,
  textarea {
    border-color: rgba(212, 212, 216, 1);
    border-radius: 0.375rem;
    color: inherit;
    line-height: 24px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
  }

  & input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  select {
    height: 42px;
  }

  & input[type='checkbox'],
  input[type='radio'] {
    opacity: unset;
    z-index: 1;
    float: none;
    margin-right: 10px;
    border-color: rgba(212, 212, 216, 1);
    border-radius: 0.25rem;
  }

  & .v-checkbox label {
    font-family: Roboto;
    color: #696969;
    font-weight: 300;
    line-height: 1.6;
    font-size: 15px;
    margin: 0;
  }

  & button.its-ak-mr-4 {
    margin-right: 1rem;
  }

  & .bg1 {
    background: $bg1 !important;
  }

  & .bg2 {
    background: $bg2 !important;
  }

  & .bg3 {
    background: $bg3 !important;
  }

  & .bg4 {
    background: $secondary !important;
  }

  & .txtsize1 {
    font-size: $txt1;
  }

  & .txtsize2 {
    font-size: $txt2;
  }

  & .txtsize3 {
    font-size: $txt3;
  }
  & .txtsize4 {
    font-size: $txt4;
  }
  & .txtsize5 {
    font-size: $txt5;
  }
  & .txtsize6 {
    font-size: $txt6;
  }
  & .txtsize7 {
    font-size: $txt7;
  }

  & .txtclr_prim {
    color: $primary;
  }

  & .txtclr_sec {
    color: $secondary;
  }

  & .txtclr1 {
    color: $txt-clr1;
  }
  & .txtclr2 {
    color: $txt-clr2;
  }
  & .txtclr3 {
    color: $txt-clr3;
  }
  & .txtclr4 {
    color: $txt-clr4;
  }
  & .txtclr5 {
    color: $txt-clr5;
  }
  & .txtclr6 {
    color: $txt-clr6;
  }
  & .txtclr7 {
    color: $txt-clr7;
  }

  & .bolder_ak {
    font-weight: 400;
  }

  & strong {
    font-weight: 400;
  }

  & p {
    margin-bottom: 20px;
  }

  & a:hover {
    color: $primary;
  }

  & .button_ak {
    background: #1f7b9a;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid transparent;
    box-shadow: none;
    padding: 0 40px;
    line-height: 53px;
    font-size: 11px;
    border-radius: 500px;
    transition: 0.1s ease-in-out;
    transition-property: all;
    transition-property: color, background-color, background-position,
      border-color, box-shadow;
    font-family: Roboto;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    height: 53px;
  }

  & .button_ak:hover {
    background: #4c96ae;
    color: rgba(255, 255, 255, 1);
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-block;
  }

  & .button_ak.fit {
    width: 100%;
  }

  & .button_ak_small {
    padding: 0 20px;
    line-height: 35px;
    font-size: 11px;
    color: $txt-clr4;
    height: 37px;
  }

  & .button_ak_light {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
  }

  & .button_ak_light:hover {
    background: #fff;
    color: rgba(46, 126, 237, 0.7);
    border-color: rgba(46, 126, 237, 0.7);
  }

  & .button_ak_more,
  .button_ak_more:hover {
    border-radius: 0;
    line-height: 25px;
    font-size: 11px;
    background: $bg1;
    color: $primary;
    border: none;
    position: relative;
    height: 25px;
  }

  & .button_ak_more:hover {
    color: $txt-clr1;
  }

  & .icon_ak {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
    vertical-align: middle;
  }

  & .icon_ak [stroke*='#'] {
    stroke: currentcolor;
  }

  & .button_ak_more .icon_ak {
    position: absolute;
    right: 5px;
    top: 2px;
  }

  & .wrapper_back_button {
    margin: 30px 0;
    text-align: center;
  }

  & .marken,
  .modelle {
    background: $bg3;
  }

  & .wrapper_make {
    padding: 20px;
    transition: background-color 0.1s linear;
    cursor: pointer;

    & .make_img {
      background-color: $bg4;
      border-radius: 8px;
    }

    & .make {
      font-size: $txt6;
      line-height: 1.4;
      font-weight: 300;
      color: $txt-clr4;
      display: block;
      text-align: center;
    }

    & .make_sub {
      font-size: ($txt6 * 0.6);
      line-height: 1.4;
      font-weight: 300;
      color: $txt-clr6;
      display: block;
      text-align: center;
    }

    & .txt1,
    .price {
      color: $txt-clr7;
    }

    & .txt1 {
      font-size: $txt3;
      line-height: 1.5;
      font-weight: 300;
    }

    & .price {
      font-size: ($txt3 * 2.2);
      line-height: 1.1;
      font-weight: 500;
    }
  }

  & .wrapper_make:nth-child(even) {
    background: $bg3 !important;
    &:hover {
      background: $bg5 !important;
    }
  }

  & .wrapper_make:nth-child(odd) {
    background: $bg2 !important;
    &:hover {
      background: $bg5 !important;
    }
  }

  & .wrapper_make:nth-child(odd) .button_ak {
    background: #4c96ae;
    &:hover {
      background: #1f7b9a;
    }
  }

  & .modelle {
    padding: 40px 0;
    & .model_headline {
      text-align: center;
      font-size: 52px;
      line-height: 1.2;
      color: #fff;
      font-weight: 100;

      & .icon_ak {
        margin-bottom: 30px;
      }
    }
  }

  & .wrapper_model {
    padding: 0 20px;
    margin-bottom: 20px;

    & .model_headline {
      text-align: center;
      font-size: $txt7;
      line-height: 1.1;
      color: $txt-clr4;
      font-weight: 100;
    }

    & .wrapper_model_inner {
      padding: 15px;
      background-color: $bg4;
      text-align: center;
      position: relative;
      padding-bottom: 100px;
      cursor: pointer;
      height: 100%;

      & .model_image {
        transition: all 0.2s ease-in-out;
      }

      &:hover .model_image {
        transform: scale(1.03, 1.03);
      }

      & .wrapper_button {
        position: absolute;
        bottom: 20px;
        width: 100%;
      }

      & .wrapper_model_image {
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
      }

      & .make_model1 {
        font-size: $txt4;
        color: $primary;
        border-bottom: 1px solid $primary;
        margin-bottom: 15px;
        font-weight: 300;
      }

      & .price {
        font-size: $txt6;
        color: $secondary;
      }

      & .price {
        font-size: $txt6;
        color: $secondary;
      }

      & .text_panel p {
        font-size: $txt2;
        line-height: 1.5;
        color: $txt-clr1;
      }
    }
  }

  & .detail {
    font-family: Roboto;
    color: $txt-clr1;
    font-weight: 300;
    line-height: 1.6;
    font-size: 15px;

    & p {
      margin-bottom: 20px;
    }

    & .wrapper_images {
      height: 300px;
      overflow: hidden;
    }

    & .wrapper_ad_image {
      margin: 20px 0;
    }

    & .detail_headline {
      line-height: 1.1;
      font-weight: 100;
    }

    & .category {
      margin-top: 10px;
      padding-bottom: 10px;
      border-bottom: calc(0.2px + 0.05em) solid $primary;
    }

    & .offer_top,
    .offer_main {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #e9e9e9;
    }

    & .number {
      margin-bottom: 40px;
    }

    & .price,
    .note1,
    .discount {
      line-height: 1.1;
      font-weight: 300;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e9e9e9;
    }

    & .wrapper_button .button_ak {
      margin-top: 20px;
    }

    & .model_headline {
      font-weight: 400;
      border-bottom: 2px solid $primary;
      margin-bottom: 10px;
    }

    & .offer_main1 {
      padding: 20px 35px;
    }

    & .offer_main2 {
      padding: 0px 10px;
      border-left: 1px solid $bg1;

      & hr {
        margin: 20px 0;
      }
    }

    & .detail_thumb {
      width: 100px;
    }

    & .wrapper_model_desc {
      & ul {
        list-style: disc;
        margin: 0 0 10px 25px !important;
      }
    }

    & .pdf_box {
      padding: 20px 30px 20px 70px;
      color: $txt-clr7;
      cursor: pointer;
      position: relative;
      margin-bottom: 20px;

      & .pdf_box_icon {
        position: absolute;
        color: #fff;
        left: 20px;
      }

      & .pdf_box_title {
        color: $txt-clr4;
        font-size: $txt4;
      }
    }

    & .pdf_box:hover {
      background: $bg5;

      & .pdf_box_title {
        color: $secondary;
      }
    }

    & .wrapper_model_specs {
      padding-bottom: 20px;
      margin-bottom: 40px;
      border-bottom: 1px solid #e9e9e9;
    }

    & .model_specs_headline {
      font-weight: 400;
      border-bottom: 1px solid $primary;
      margin-bottom: 10px;
    }

    & .specs_head {
      font-size: $txt4;
      margin-bottom: 15px;
    }

    & .wrapper_cons_text {
      margin-top: 20px;
      font-size: $txt1;
    }

    & .anfrage_head {
      margin-bottom: 40px;

      & .head1 {
        text-align: center;
      }

      & .head2 {
        font-size: 30px;
        padding: 30px;
      }
    }

    & .anfrage_desc {
      padding: 20px 35px;

      & .anfrage_desc_head {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }

    & .wrapper_form section svg {
      width: 30px;
      height: 30px;
    }

    & .v-checkbox .label {
      position: relative;
      top: -5px;
      margin: 0 0 5px 0;
      padding: 0;
      background: none;
      text-transform: none;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import Marken from '@/components/Marken.vue'
import Modelle from '@/components/Modelle.vue'
import Detail from '@/components/Detail.vue'

export default {
  name: 'Aktionsangebote',
  components: {
    Marken,
    Modelle,
    Detail,
  },
  props: {
    orderAgentToken: String,
    channel: String,
  },
  computed: {
    ...mapGetters('env', ['getCurrentComp']),
  },
  methods: {
    ...mapActions('env', ['loadAgentData']),
  },
  mounted() {
    this.loadAgentData()
  },
}
</script>
