<template>
  <div v-if="getModelle && $store.state.env.langLoaded" class="modelle">
    <div class="model_headline">
      <span class="icon_ak">
        <svg
          width="100"
          height="100"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.74,20 L7.73,12 L3,12 L15.43,1 L12.32,9 L17.02,9 L4.74,20 L4.74,20 L4.74,20 Z M9.18,11 L7.1,16.39 L14.47,10 L10.86,10 L12.99,4.67 L5.61,11 L9.18,11 L9.18,11 L9.18,11 Z"
          ></path>
        </svg>
      </span>
      <br />
      <span v-html="getModelle.headline" />
      <br />
      {{ $t('blitz-angebote') }}: <span v-html="getCurrentMarkeName" />
    </div>
    <div v-if="!makeMode" class="wrapper_back_button">
      <button class="button_ak button_ak_light" @click="back">
        {{ $t('zurueck-zur-markenuebersicht') }}
      </button>
    </div>
    <div
      class="
        its-ak-grid
        sm:its-ak-grid-cols-1
        md:its-ak-grid-cols-2
        lg:its-ak-grid-cols-3
        md:its-ak-divide-x md:its-ak-divide-gray-50
        its-ak-mt-6
      "
    >
      <div
        v-for="(model, mIx) in getModelle.list_items"
        :key="mIx"
        @click="offers(model.id)"
        class="wrapper_model"
      >
        <div class="wrapper_model_inner">
          <div class="wrapper_model_image">
            <img class="model_image" :src="model.so_image" />
          </div>
          <div class="make_model1" v-html="model.so_make_model1" />
          <div class="price" v-html="model.so_price" />
          <div class="text_panel" v-html="model.so_text_panel" />
          <div class="wrapper_button">
            <div class="button_ak button_ak_small">{{ $t('zum-angebot') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!makeMode" class="wrapper_back_button">
      <button class="button_ak button_ak_light" @click="back">
        {{ $t('zurueck-zur-markenuebersicht') }}
      </button>
    </div>
  </div>

  <div v-else class="loading">...loading</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Modelle',
  computed: {
    ...mapGetters('filter', ['getModelle', 'getCurrentMarkeName']),
    ...mapGetters('env', ['makeMode']),
  },
  methods: {
    ...mapActions('filter', ['loadModelle', 'loadDetail']),
    ...mapActions('env', ['routeHome']),
    ...mapMutations('env', ['setCurrentComp']),
    ...mapMutations('filter', ['setCurrentModel']),
    async offers(id) {
      this.setCurrentModel(id)
      await this.loadDetail()
      this.setCurrentComp('Detail')

      this.$store.dispatch('formData/initAddBoxes')
      if (
        this.$store.state.env.isLocal &&
        !this.$store.state.formData.formData.surname
      ) {
        this.$store.commit('formData/initFormData')
        this.$store.commit('formData/setStep', 1)
      }
    },
    back() {
      this.routeHome()
    },
  },
}
</script>

<style lang="scss"></style>
