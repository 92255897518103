import mkFormData from '@helper/formData.js'
import apiClient from '@/services/ApiClient'

const isDevEnv = function () {
  return (
    window.location.host.match(/^localhost/) !== null ||
    window.location.host.match(/^192/) !== null ||
    window.location.host.match(/^aktionsangebote.dangp.de$/) !== null ||
    window.location.host.match(/^nwo.its-gering.de$/) !== null
  )
}

// Mapping Markt => Sprache falls nicht gleich (Sprache kann unabhängig vom Markt sein, da z. B. in Österreich deutsch gesprochen wird)
const lang = {
  de: 'de',
  it: 'it',
}

export default {
  namespaced: true,
  state: {
    langLoaded: false,
    sitekey: isDevEnv()
      ? '6LdfW8oUAAAAAK3d-0C1K-XvPlhFh5Z7Dme75QJ9'
      : '6LdhA_wUAAAAAJwVLykGj7JdxILYpDoAk-f12-7f',
    make: '',
    makeMode: false,
    market: 'de',
    currentComp: 'Marken',
    currentMake: null,
    orderAgentToken: '', // 'hzODinUy8TmgfbUdqQAgxo1093',
    agentData: {},
    apiHost: isDevEnv() ? '' : 'https://portal-live.newcar-online.com', // sonst Probleme bei der Entwicklung //https://neuwagen-internet.de //https://portal-live.newcar-online.com
    // apiPraefix: '/api/',
    isLocal: isDevEnv(),
    isMobile: false,
    // old
    currentBreakpoint: 'xs',
    baseUrl: isDevEnv() ? '/_b2bportal/so-widget/' : '/so-widget/', // /so-widget/
    imageUrl: isDevEnv()
      ? '/_b2bportal/site/templates/styles/images/ak-widget/'
      : '/site/templates/styles/images/ak-widget/', // /site/templates/styles/images/ak-widget/
  },
  mutations: {
    setCurrentComp(state, currentComp) {
      state.currentComp = currentComp
    },
    async setAgentData(state, agentData) {
      state.agentData = agentData
    },
    setCurrentBreakpoint(state, currentBreakpoint) {
      state.currentBreakpoint = currentBreakpoint
    },
    setMobile(state, isMobile) {
      state.isMobile = isMobile
    },
    setAgentToken(state, token) {
      state.orderAgentToken = token
    },
    setMake(state, make) {
      state.make = make
    },
    setMakeMode(state, makeMode) {
      state.makeMode = makeMode
    },
    setMarket(state, market) {
      state.market = market
    },
  },
  getters: {
    getSitekey: (state) => state.sitekey,
    getCurrentComp: (state) => state.currentComp,
    isMobile: (state) => state.isMobile,
    getAgentData: (state) => state.agentData,
    getBaseUrl: (state) => state.baseUrl,
    getImageUrl: (state) => state.apiHost + state.imageUrl,
    getAgentToken: (state) => state.orderAgentToken,
    getCurrentBreakpoint: (state) => state.currentBreakpoint,
    makeMode: (state) => state.makeMode,
    getMarket: (state) => state.market,
    getLang: (state) => lang[state.market] ?? state.market,
  },
  actions: {
    routeHome(context) {
      context.commit('setCurrentComp', 'Marken')
      context.commit('formData/setStep', 1, { root: true })
    },
    routeModelle(context) {
      context.commit('setCurrentComp', 'Modelle')
      context.commit('formData/setStep', 1, { root: true })
    },
    async loadAgentData(context) {
      const data = mkFormData({
        order_agent_token: context.rootState.env.orderAgentToken,
        market: context.rootState.env.market,
        mode: 'getAgentData',
      })

      try {
        const response = await apiClient.post('/', data)
        context.commit('setAgentData', response.data)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
